import React from "react";
import { Link, graphql, navigate } from "gatsby";
import Image from "gatsby-image";
import parse from "html-react-parser";
import WPGBlocks from "react-gutenberg";
import Button from "../components/Button";
import formatDate from "../../utils/formatDate";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';

const Categories = ({ categories }) => {
  // Commas between categories
  return (
    <p className={"block text-secondary font-medium"}>
      {categories?.map(
        (category, index) =>
          category + (index < categories.length - 1 ? ", " : "")
      )}
    </p>
  );
};

const BlogPostTemplate = ({ data }) => {
  const { next, post } = data;
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  };

  const categories = post?.categories?.nodes?.map((category) => category.name);
  const suggestedPostCategories = next?.categories?.nodes?.map(
    (category) => category.name
  );

  const goBack = async () => {
    if (typeof window !== "undefined") {
      if (window.history.length > 1) {
        await navigate(-1);
      } else {
        await navigate("/news");
      }
    }
  };

  const DepartmentCta = () => {
    if (categories.includes("Health")) {
      return <Button to={"/health"} text={"Go To Health"} color={"health"} />;
    } else if (categories.includes("Commercial")) {
      return (
        <Button
          to={"/commercial"}
          text={"Go To Commercial"}
          color={"commercial"}
        />
      );
    } else if (categories.includes("Education")) {
      return (
        <Button
          to={"/education"}
          text={"Go To Education"}
          color={"education"}
        />
      );
    } else if (categories.includes("Facilities")) {
      return (
        <Button
          to={"/facilities"}
          text={"Go To Facilities"}
          color={"Facilities"}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Layout>
      <Seo post={post} />
      <div
        className={
          "content-container pt-4 pb-16 tablet:py-14 tablet:pt-24 desktop:py-14"
        }
      >
        <div className={"grid grid-cols-1 desktop:grid-cols-[0.25fr_0.75fr]"}>
          <div
            className={
              "relative tablet:absolute tablet:top-8 desktop:relative desktop:top-0"
            }
          >
            <Button onClick={goBack} text={"Go Back"} iconPrefix flipIcon />
          </div>

          {/*Content*/}
          <div className={"ml-0 mt-6 tablet:mt-0 tablet:ml-32 desktop:ml-0"}>
            {/*Header*/}
            <div
              className={
                "grid grid-cols-1 gap-y-3 desktop:flex desktop:gap-6 py-3"
              }
            >
              <p
                className={
                  "text-5xl text-secondary tablet:text-6xl desktop:text-8xl !leading-[0.8]"
                }
              >
                {formatDate(post?.date)}
              </p>

              <div>
                {/*Title*/}
                <h1
                  className={
                    "text-xl leading-[1.1] text-secondary font-bold mb-4"
                  }
                >
                  {parse(post.title)}
                </h1>

                {/*Tags*/}
                <div>
                  <Categories categories={categories} />
                </div>
              </div>
            </div>

            {/*Cover image*/}
            <div className={"w-full max-h-[80vh] overflow-hidden"}>
              <Image fluid={featuredImage.fluid} />
            </div>

            {/*Post content*/}
            <div className={"mt-6 font-medium"}>
              <WPGBlocks blocks={post.blocks} />
            </div>

            {/*Actions*/}
            <div
              className={"hidden flex-col gap-3 mt-16 *:self-start tablet:flex"}
            >
              <Button
                to={"mailto:enquiries@bauvill.co.uk"}
                isExternal={true}
                text={"Get In Touch"}
              />
              <DepartmentCta />
            </div>

            {/*Suggested post*/}
            {next && (
              <div
                className={
                  "py-8 mt-16 border-y-2 border-secondary grid grid-cols-1 tablet:grid-cols-2"
                }
              >
                <div>
                  <p
                    className={
                      "text-5xl text-secondary mb-3 tablet:text-6xl desktop:text-8xl"
                    }
                  >
                    {formatDate(next?.date)}
                  </p>

                  <h2
                    className={
                      "text-lg text-secondary font-bold mb-4 leading-[1.1] tablet:text-xl"
                    }
                  >
                    {next?.title}
                  </h2>
                </div>

                <div className={"grid grid-cols-1 "}>
                  <div className={"tablet:flex tablet:justify-end"}>
                    <Categories categories={suggestedPostCategories} />
                  </div>

                  <div
                    className={
                      "w-full text-center mt-6 tablet:text-left tablet:mt-0 tablet:flex tablet:justify-end tablet:items-end"
                    }
                  >
                    <Button to={next?.uri} text={"Next Post"} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
        }
      }
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        metaRobotsNoindex
        metaRobotsNofollow
      }
      blocks {
        blockName: name
        innerHTML: saveContent
        dynamicContent
        innerBlocks {
          blockName: name
          innerHTML: saveContent
          # attrs {
          #   blockId
          #   blockUniqueClass
          #   custom_name
          # }
          innerBlocks {
            blockName: name
            innerHTML: saveContent
            # attrs {
            #   blockId
            #   blockUniqueClass
            #   custom_name_in_column
            # }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 75) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
        }
      }
    }
  }
`;
